.db-table {
  @apply border-collapse;
  @apply table-fixed;
  border-spacing: 0;

  thead {
    @apply m-0;
    @apply sticky;
    @apply top-0;
    @apply bg-db-background-color;
    @apply z-10;
    @apply left-0;

    th {
      @apply px-4;
      @apply py-2;

      &:first-child {
        @apply text-left;
        @apply sticky;
        @apply left-0;
        background-color: #121212;
      }
    }
  }

  tbody {
    @apply bg-db-header-background-color;

    tr {
      @apply border-b-[2px];
      @apply border-b-gray;
      @apply z-0;

      &:hover,
      &.selected {
        @apply bg-gray;
      }

      &:active {
        background-color: #2e2e2e;
        transition: background-color 0.3s ease;
      }

      &:first-child {
        @apply border-t-[1px];
        @apply border-t-gray;
      }

      td {
        @apply px-2;
        @apply py-2;
        @apply min-w-[80px];
        @apply text-center;

        &:first-child {
          @apply sticky;
          @apply left-0;
          background-color: rgb(30 30 30 / var(--tw-bg-opacity));
        }
      }

      &:not(.selected) {
        &.stop-selection {
          cursor: pointer;
        }
      }
    }
  }

  .highlightCell {
    background-color: #364544 !important;
  }
}
