.border-green {
  border-color: green;
}

.border-blue {
border-color: #1d5d90;
}

.border-default {
  border-color: whitesmoke;
}
