// TODO: remove this once we convert to mui inputs
.input-holder {
  @apply mt-4;

  input {
    @apply text-primary;
    @apply border-b-[1px];
    @apply border-solid;
    @apply border-b-gray;
    @apply w-full;
    @apply px-2;
    @apply py-2;
    @apply outline-none;
    @apply bg-transparent;

    &[type='date']::-webkit-calendar-picker-indicator {
      cursor: pointer;
      border-radius: 4px;
      margin-right: 2px;
      opacity: 0.6;
      filter: invert(0.3);
    }

    &:focus,
    &:focus-visible {
      @apply outline-none;
      @apply border-b-primary;
    }

    &::placeholder {
      @apply text-primary;
    }

    &:-ms-input-placeholder {
      @apply text-primary;
    }

    &::-ms-input-placeholder {
      @apply text-primary;
    }
  }

  textarea {
    @apply text-primary;
    @apply border-b-[1px];
    @apply border-solid;
    @apply border-b-gray;
    @apply w-full;
    @apply px-2;
    @apply py-2;
    @apply outline-none;
    @apply bg-transparent;
    @apply min-h-[6rem];

    &:focus,
    &:focus-visible {
      @apply outline-none;
      @apply border-b-primary;
    }

    &::placeholder {
      @apply text-primary;
    }

    &:-ms-input-placeholder {
      @apply text-primary;
    }

    &::-ms-input-placeholder {
      @apply text-primary;
    }
  }

  .react-datepicker {
    @apply font-source;
    @apply bg-db-background-color;
    @apply text-primary;
    @apply border-gray;

    .react-datepicker__header {
      @apply bg-db-header-background-color;
      @apply text-primary;
      @apply border-accent;
      @apply border-b-accent;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        @apply text-primary;
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2.4rem;
      line-height: 2.4rem;
      @apply text-primary;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      @apply bg-accent;
      @apply text-black;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      @apply bg-accent;
      @apply text-black;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      @apply bg-accent;
      @apply text-black;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      @apply bg-db-background-color;
      &.react-datepicker__time-list-item--selected {
        @apply bg-accent;
        @apply text-black;
      }
    }

    .react-datepicker__time-container {
      @apply border-l-accent;
      @apply w-auto;
    }

    button {
      &.react-datepicker__navigation {
        &:hover *::before {
          @apply border-accent;
        }
        @apply mt-3;
      }
    }
  }
}

button {
  &.google-btn {
    @apply bg-gray;
    @apply h-11;
    @apply w-11;
    @apply p-2;
  }

  @apply text-black;
  @apply rounded-full;
  @apply px-8;
  @apply py-3;
  @apply font-semibold;

  &:disabled {
    pointer-events: none;
    @apply bg-gray;
  }
}

.checkbox-container {
  @apply mt-4;
  @apply relative;
  @apply pl-9;
  @apply mb-3;
  @apply cursor-pointer;
  @apply text-2xl;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  @apply absolute;
  @apply opacity-0;
  @apply cursor-pointer;
  @apply h-0;
  @apply w-0;
}

.checkmark {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply h-7;
  @apply w-7;
  @apply bg-transparent;
  @apply border-2;
  @apply border-gray;
  @apply rounded;
}

.checkbox-container:hover input ~ .checkmark {
  @apply bg-transparent;
}

.checkbox-container input:checked ~ .checkmark {
  @apply bg-transparent;
}

.checkmark:after {
  content: '';
  @apply absolute;
  @apply hidden;
}

.checkbox-container input:checked ~ .checkmark:after {
  @apply block;
}

.checkbox-container .checkmark:after {
  @apply left-[0.55rem];
  @apply top-1;
  @apply w-[6px];
  @apply h-3;
  @apply border-0;
  @apply border-r-[3px];
  @apply border-b-[3px];
  @apply rotate-45;
}

.file-input {
  @apply w-60;
  @apply h-60;
  @apply mt-4;

  .file {
    @apply opacity-0;
    @apply w-[0.1px];
    @apply h-[0.1px];
    @apply absolute;
  }

  label {
    @apply block;
    @apply relative;
    @apply w-full;
    @apply h-full;
    @apply rounded-3xl;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply text-primary;
    @apply cursor-pointer;
    @apply border-gray;
    @apply border-2;
    // @apply text-gray;
    @apply bg-db-background-color;
    @apply font-normal;
    @apply overflow-hidden;
  }
}
