.c-multi-select-dropdown {
  @apply relative;

  &:hover {
    .c-multi-select-dropdown__options {
      @apply block;
    }
  }

  .c-multi-select-dropdown__selected {
    @apply border-2;
    @apply border-gray;
    @apply rounded;
    @apply p-2;
    @apply flex;
    @apply items-center;
    @apply justify-center;

    &:hover {
      @apply cursor-pointer;
    }
  }

  .c-multi-select-dropdown__options {
    @apply bg-black;
    @apply border-2;
    @apply border-gray;
    @apply rounded;
    @apply list-none;
    @apply hidden;
    @apply absolute;
    @apply w-auto;
    @apply z-[11];
    @apply right-0;
  }

  .c-multi-select-dropdown__option {
    @apply flex;
    @apply items-center;
    @apply px-4;
    @apply min-w-[180px];

    &:hover {
      background-color: rgb(30 30 30 / var(--tw-bg-opacity));
    }
  }
}
