::-webkit-scrollbar {
  width: 0px;
}

.league-item-wrapper {
  display: flex;
  overflow-x: scroll;
  width: 100%;

  .league-item {
    flex-shrink: 0;
    @apply bg-db-header-background-color;
    @apply border-[1px];
    @apply border-gray;
    @apply shadow-lg;

    &.drafting {
      @apply relative;
      animation: border-blink-league 5s infinite;

      // &:after {
      //   content: '';
      //   @apply text-accent;
      //   @apply text-xs;
      //   @apply font-bold;
      //   @apply rounded-full;
      //   @apply absolute;
      //   @apply h-3;
      //   @apply w-3;
      //   @apply top-3;
      //   @apply right-3;
      //   @apply z-0;
      //   @apply bg-accent;
      //   animation: blink 5s infinite;
      // }
    }

    &:not(:last-child) {
      @apply mr-4;
    }

    .image {
      position: relative;
      @apply border-b-[1px];
      @apply border-b-gray;

      &::after {
        position: absolute;
        bottom: 0px;
        content: '';
        width: 100%;
        box-shadow: 0 0 70px 50px #000000;
      }
    }

    .details {
      .members-list {
        > div {
          &:not(:first-child) {
            margin-left: -8px;
          }
          .image-holder {
            box-shadow: 0 0 10px 2px #000000;
          }
        }
      }
    }
  }
}

.scroll-container-outer {
  max-width: 560px; /* Adjust the max width as needed */
  position: relative;
  margin: 0 auto; /* Center the container horizontally */
}

/* Scroll arrow styles */
.scroll-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(100%);
  cursor: pointer;
  font-size: 70px; /* Adjust the size as needed */
  color: #333; /* Adjust the color as needed */
  z-index: 99;
}

.left {
  left: 0;
  padding-left: 0.5rem;
}

.right {
  right: 0;
  padding-right: 0.5rem;
}
