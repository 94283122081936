.db-select {
  .container {
    @apply w-full;
    @apply relative;

    button {
      @apply text-primary;
      @apply border-b-[1px];
      @apply border-solid;
      @apply border-b-gray;
      @apply w-full;
      @apply px-2;
      @apply py-2;
      @apply outline-none;
      @apply bg-transparent;
      @apply rounded-none;
      @apply font-normal;
      @apply text-left;
    }

    button:hover {
      cursor: pointer;
    }

    button::after {
      @apply absolute;
      @apply right-4;
      @apply top-2;
      @apply text-gray;
      transform: rotateZ(90deg) scaleY(1.8);
      content: '>';
    }

    button.expanded::after {
      content: '<';
    }

    ul.options {
      @apply hidden;
      @apply list-none;
      @apply absolute;
      @apply w-full;
      @apply z-[1];
      @apply bg-db-background-color;
      //@apply pb-4;
      @apply max-h-[320px];
      @apply overflow-scroll;
    }

    ul.show {
      @apply block;
      @apply shadow-lg;
    }

    ul.options li {
      @apply p-2;

      @apply border-[1px];
      @apply border-gray;
      @apply border-t-0;
    }

    ul.options li:active,
    ul.options li:focus,
    ul.options li:hover,
    ul.options li[aria-selected='true'] {
      @apply bg-gray;
      @apply cursor-pointer;
    }
  }
}
