.matchup-header {
  .win-l {
    @apply relative;
    &::after {
      content: 'W';
      @apply absolute;
      @apply -left-10;
      @apply top-1;
      @apply bg-[#065605];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .loss-l {
    @apply relative;
    &::after {
      content: 'L';
      @apply absolute;
      @apply -left-10;
      @apply top-1;
      @apply bg-[#81280C];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .tie-l {
    @apply relative;
    &::after {
      content: 'T';
      @apply absolute;
      @apply -left-10;
      @apply top-1;
      @apply bg-[#4500FF];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .win-r {
    @apply relative;
    &::after {
      content: 'W';
      @apply absolute;
      @apply -right-10;
      @apply top-1;
      @apply bg-[#065605];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .loss-r {
    @apply relative;
    &::after {
      content: 'L';
      @apply absolute;
      @apply -right-10;
      @apply top-1;
      @apply bg-[#81280C];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .tie-r {
    @apply relative;
    &::after {
      content: 'T';
      @apply absolute;
      @apply -right-10;
      @apply top-1;
      @apply bg-[#4500FF];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }
}
