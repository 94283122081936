.tab-header {
  @apply mt-3;
  @apply mb-[-1px];
  text-transform: uppercase;

  li {
    @apply cursor-pointer;

    &.selected {
      //   @apply text-black;
      border-bottom: 3px solid #00BE29;
      padding-bottom: 3px;
      color: #00BE29;
    }

    &.disabled {
      @apply pointer-events-none;
      @apply opacity-20;
    }
  }
}