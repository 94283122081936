.image {
  position: relative;
  @apply border-b-[1px];
  @apply border-b-gray;

  &::after {
    position: absolute;
    bottom: 0px;
    content: '';
    width: 100%;
    box-shadow: 0 0 80px 100px #000000;
  }
}

.floating-join-btn {
  transform: translateY(-26px);
  position: absolute;
  right: 0;
}

.disabled {
  @apply opacity-40;
  @apply cursor-not-allowed;
  @apply pointer-events-none;
}

.matchup-item {
  .win-l {
    @apply relative;

    &::after {
      content: 'W';
      @apply absolute;
      @apply -left-1;
      @apply -top-[0.25rem];
      @apply bg-[#065605];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .loss-l {
    @apply relative;

    &::after {
      content: 'L';
      @apply absolute;
      @apply -left-1;
      @apply -top-[0.25rem];
      @apply bg-[#81280C];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .tie-l {
    @apply relative;

    &::after {
      content: 'T';
      @apply absolute;
      @apply -left-1;
      @apply -top-[0.25rem];
      @apply bg-[#4500FF];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .win-r {
    @apply relative;

    &::after {
      content: 'W';
      @apply absolute;
      @apply -right-1;
      @apply -top-6;
      @apply bg-[#065605];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .loss-r {
    @apply relative;

    &::after {
      content: 'L';
      @apply absolute;
      @apply -right-1;
      @apply -top-6;
      @apply bg-[#81280C];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }

  .tie-r {
    @apply relative;

    &::after {
      content: 'T';
      @apply absolute;
      @apply -right-1;
      @apply -top-6;
      @apply bg-[#4500FF];
      @apply w-6;
      @apply h-6;
      @apply text-base;
      @apply rounded;
      @apply text-primary;
      @apply text-center;
    }
  }
}

.draft-sequence::-webkit-scrollbar {
  display: none;
}