.container {
  position: relative;
  text-align: center;
  color: white;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  @apply text-3xl
}
.inactive-banner {
  position: absolute;
  top: 0px;
  left: 0px;
  @apply bg-[#00BE2A];
}
