.db-list {
  @apply flex-shrink-0;
  @apply bg-db-background-color;
  @apply rounded-t-2xl;
  @apply flex flex-col;

  .header {
    @apply bg-gray;
    @apply text-primary;
    @apply rounded-t-2xl;
    @apply px-4;
    @apply pt-2;
    @apply pb-1;
  }

  .db-list-items-wrapper {
    @apply border-2;
    @apply border-gray;
    @apply rounded-b-2xl;
    @apply max-h-96;
    @apply overflow-y-scroll;
  }

  .db-list-item-wrapper {
    @apply min-h-[46px];
    @apply text-sm;
    &:not(:last-child) {
      @apply border-b-[1px];
      @apply border-gray;
    }
  }

  .db-list-item {
    @apply px-4;
    @apply py-3;
    &.short {
      @apply px-3;
      @apply py-2;
    }
  }

  .db-select {
    @apply mt-0;

    button {
      @apply border-b-[1px];
      @apply border-b-primary;
      @apply pb-0;
      @apply mb-2;
      @apply mr-8;
      span {
        @apply text-primary;
      }
      &:after {
        @apply text-primary;
      }
    }
  }
}

.list-300 {
  min-width: 300px;
}
