.db-modal-backdrop {
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply right-0;
  @apply w-full;
  @apply h-full;
  @apply flex; /* Use flex to center content */
  @apply justify-center;
  @apply items-center;
  @apply bg-[#1c1c1ce1];
  @apply z-[99];

  .db-modal-content {
    @apply relative; /* Use relative instead of absolute */
    @apply h-[100vh]; /* Set a max height to allow scrolling */
    @apply w-full;
    // @apply m-auto;
    @apply max-w-[560px];
    @apply bg-db-background-color;
    @apply rounded-t-2xl;
    @apply overflow-y-auto; /* Enable vertical scrolling */
  }
}
