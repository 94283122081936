.result-header {
  @apply text-primary;
  @apply rounded-t-2xl;
  @apply px-4;
  @apply pt-2;
  @apply pb-1;
}

.result-list-item {
  @apply min-h-[46px];
  @apply text-sm;
}

.vs-circle {
  @apply border-gray;
  @apply h-6;
  @apply rounded-full;
  @apply w-6;
  @apply text-base;
  @apply text-primary;
  @apply border-2;
  @apply text-center;
  @apply bg-gray;
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.separator {
  @apply flex;
  @apply items-center;
  @apply text-center;
}

.separator::before,
.separator::after {
  content: '';
  @apply flex-1;
  @apply border-b-2;
  @apply border-gray;
}
